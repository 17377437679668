import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './properties.css'
import {message,TreeSelect} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
//import 'moment/locale/ar';
import {SearchOutlined} from '@ant-design/icons';

const { TreeNode } = TreeSelect;
let headers = [
  { label: "#", key: "id" },
  { label: "Price Per Meter", key: "pricePerMeter" },
  { label: "Project", key: "project" },
  { label: "Location", key: "location" },
  { label: "Category", key: "category" },
];
 
let csvData = [
  
];
class properties extends React.Component {
  
  state={
    properties:[],
    properties2:[],
    categories:[],
    locations:[],
    csvData:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    collapsed: false,
    search:null,
    category:null,
    project:null,
    type:null,
    filterType:{},
    location:null,
    value:'',
    projects:[]
  }

  constructor(props){
    super(props)
    this.getPropertiessWithoutPagenation()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    let pageNum = this.state.page
    if(sessionStorage.getItem('pageName') === "PROPERTIES"){
      pageNum = sessionStorage.getItem('pageNum');
      this.setState({page:pageNum})
    }
    this.getProperties(pageNum)
    this.getCategory()
    this.getLocations()
    this.getProjects()

    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getProjects = () => {
    axios.get(`${BASE_END_POINT}projects/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({projects:response.data.data})
    })
    .catch(error=>{
      console.log("ALL projects ERROR")
      console.log(error.response)
    })
  }
  getLocations = () => {
    axios.get(`${BASE_END_POINT}location/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({locations:response.data.data})
    })
    .catch(error=>{
      console.log("ALL locations ERROR")
      console.log(error.response)
    })
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data.data})
    })
    .catch(error=>{
      console.log("ALL categories ERROR")
      console.log(error.response)
    })
  }

  getProperties = (page,rest) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}properties?all=true&forMobile=false&page=${page}`
    let {type,location,search,category,project} = this.state
    if(type !== null){
      let str = '&type='+ type
      url += str
    }
    if(location !== null){
      let str = '&location='+ location
      url += str
    }
    if(project !== null){
      let str = '&project='+ project
      url += str
    }
    if(category !== null){
      let str = '&category='+ category
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}properties?all=true&forMobile=false&page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("properties   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.type === "RELATED-TO-COMPOUND"?{type:'tag',value:allStrings.relatedToCompound}:{type:'tag',value:allStrings.separated},
        item.pricePerMeter?item.pricePerMeter :'',
        item.project?item.project.name :'',
        item.location?item.location.name :"",
        item.category?item.category.categoryName:"",
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        properties:response.data.data,
        properties2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
      sessionStorage.setItem('pageNum', page);
      sessionStorage.setItem('pageName', "PROPERTIES");
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getPropertiessWithoutPagenation = (rest) =>{
    let url = `${BASE_END_POINT}properties/withoutPagenation/get?all=true&forMobile=false`
    let {type,location,search,category,project} = this.state
    if(type !== null){
      let str = '&type='+ type
      url += str
    }
    if(category !== null){
      let str = '&category='+ category
      url += str
    }
    if(project !== null){
      let str = '&project='+ project
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}properties/withoutPagenation/get?all=true&forMobile=false`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("properties   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{type:item.type === "RELATED-TO-COMPOUND"?allStrings.relatedToCompound:allStrings.separated},
            ...{pricePerMeter:item.pricePerMeter?item.pricePerMeter :''},
            ...{project:item.project?item.project.name :''},
            ...{location:item.location?item.location.name :""},
            ...{category:item.category?item.category.categoryName:""},
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}properties/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("Row DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getProperties(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addProperty">
        <Fab
          style={{background:'#233b5b',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
      
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{dACCEPTEDlay: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getProperties(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getProperties(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getProperties(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} 
        style={{color:`#233b5b !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getProperties(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getProperties(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PROPERTIES')) return <Redirect to='/Home' />

    return (
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          
          <Table
            //enableEdit = {false}
            title={allStrings.Properties}
            icon='location_city'
            
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.setState({search:null,location:null,project:null,category:null,type:null});this.getProperties(1,true);this.getPropertiessWithoutPagenation(true)}}
                class="material-icons">
                  replay
                </span>
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"properties.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClassC':'hideClass'}>
                  <MDBRow>
                    
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.project}</label>
                      <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.project}
                            value={this.state.project}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({project:value});
                            }} 
                        
                        >
                            {this.state.projects.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                            ))}
                        </TreeSelect>
                      
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.location}</label>
                      <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.location}
                            value={this.state.location}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({location:value});
                            }} 
                        
                        >
                            {this.state.locations.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                            ))}
                        </TreeSelect>
                      
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                        <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.category}
                            value={this.state.category}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({category:value});
                            }} 
                        
                        >
                            {this.state.categories.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol md="6">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                        <TreeSelect virtual={false}
                            showSearch={false} 
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.type}
                            value={this.state.type}
                            onChange={(value) => {
                                console.log(value)
                                this.setState({type:value});
                            }} 
                        
                        >
                            <TreeNode value="RELATED-TO-COMPOUND" title={allStrings.relatedToCompound} key="RELATED-TO-COMPOUND" />
                            <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />                                                
                            
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="6">
                      <div class="md-form">
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.Search}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>

                  <Button style={{background: '#233b5b',color: '#fff',width: '100%',margin: '32px auto'}} 
                        onClick={()=>{this.getProperties(1);this.getPropertiessWithoutPagenation()}}><SearchOutlined /> {allStrings.Search}</Button>
                    
                  </MDBRow>
                  
                </div>
              </div>
            }
            data={this.state.properties}
            data2={this.state.properties2}
            tableColumns={["#",allStrings.type,allStrings.pricePerMeter,allStrings.project,allStrings.location,allStrings.category,allStrings.action]} loading={this.state.loading}
            deleteButton={(id)=>{this.delete(id)}}
            path='/propertyInfo'
            actionIndex={8}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
              
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    );
  }

}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDACCEPTEDatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDACCEPTEDatchToProps)(properties)
);

