import React from 'react';
import './project info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Image ,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;

class projectInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        name_en:this.props.location.state.data.name_en,
        name_ar:this.props.location.state.data.name_ar,
        type:this.props.location.state.data.type,
        ratio:this.props.location.state.data.ratio,
        diffOnspotRatio:this.props.location.state.data.diffOnspotRatio,
        companyRatio:this.props.location.state.data.companyRatio,
        employeName:this.props.location.state.data.employeName?this.props.location.state.data.employeName:'',
        employePhone:this.props.location.state.data.employePhone?this.props.location.state.data.employePhone.replace("+2", ""):'',
        employeCountry:this.props.location.state.data.employeCountry?this.props.location.state.data.employeCountry.id:null,
        nawyApp:this.props.location.state.data.nawyApp,
        top:this.props.location.state.data.top,
        img:this.props.location.state?this.props.location.state.data.img !== ""?this.props.location.state.data.img:null:null,
        imgName:'',
        imgType:"url",
        pdf:this.props.location.state?this.props.location.state.data.pdf !== ""?this.props.location.state.data.pdf:null:null,
        pdfType:"url",
        pdfName:"",
        latitude:this.props.location.state.data.location?this.props.location.state.data.location.coordinates[1]:null,
        longitude:this.props.location.state.data.location?this.props.location.state.data.location.coordinates[0]:null,
        theVillas:this.props.location.state.data.villas?this.props.location.state.data.villas:[{available:false,type:'TOWN', area:0,price:0},{available:false,type:'TWIN', area:0,price:0},{available:false,type:'STAND-ALONE', area:0,price:0}],
        theApartments:this.props.location.state.data.apartments?this.props.location.state.data.apartments:[{available:false,type:'ONE-BEDROOM', area:0,price:0},{available:false,type:'TWO-BEDROOM', area:0,price:0},{available:false,type:'THREE-BEDROOM', area:0,price:0},{available:false,type:'FOUR-BEDROOM', area:0,price:0},{available:false,type:'DUPLEX', area:0,price:0}],
        theMall:this.props.location.state.data.mall?this.props.location.state.data.mall:[{available:false,type:'SHOP', area:0,price:0},{available:false,type:'OFFICE', area:0,price:0},{available:false,type:'CLINIC', area:0,price:0}],
        companies:[],
        countries:[],
        company:this.props.location.state.data.company?this.props.location.state.data.company.id:null
       
    };

    constructor(props){
        super(props)
        this.getCompanies();
        this.getCountries();
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    getCountries = () => {
        axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`
            },
           })
        .then(response=>{
          console.log("countries",response.data)
          this.setState({countries:response.data.data})
        })
        .catch(error=>{
          console.log("ALL countries ERROR")
          console.log(error.response)
        })
    }
    getCompanies = () => {
        axios.get(`${BASE_END_POINT}companies/withoutPagenation/get`,{
            headers:{
              'Authorization':`Bearer ${this.props.currentUser.token}`
            }
        })
        .then(response=>{
          console.log(response.data)
          this.setState({companies:response.data.data})
        })
        .catch(error=>{
          console.log("ALL companies ERROR")
          console.log(error.response)
        })
    }
    updateproject = () => {
        const {type,name_en,name_ar,employeCountry,employePhone,employeName,img,ratio,imgType,nawyApp,top,company,companyRatio,diffOnspotRatio,/*longitude,latitude,*/pdf,pdfType} = this.state
        if(hasValue(name_ar)&&hasValue(name_en)){
            let l = message.loading('Wait..', 2.5)
            var data = new FormData()
            data.append('ratio',ratio)
            data.append('name_ar',name_ar)
            data.append('name_en',name_en)
            data.append('type',type)
            data.append('employePhone',employePhone)
            data.append('employeName',employeName)
            data.append('employeCountry',employeCountry)
            data.append('nawyApp',nawyApp)
            data.append('top',top)
            data.append('company',company)
            data.append('diffOnspotRatio',diffOnspotRatio)
            data.append('companyRatio',companyRatio)
            if(img != null &&imgType === "data"){
                data.append('img',img)
            }

            if(pdf != null &&pdfType === "data"){
                data.append('pdf',pdf)
            }
            let villas = [...new Set(this.state.theVillas.filter(e => e.type != null))]
            data.append('villas',JSON.stringify(villas))
            let apartments = [...new Set(this.state.theApartments.filter(e => e.type != null))]
            data.append('apartments',JSON.stringify(apartments))
            let mall = [...new Set(this.state.theMall.filter(e => e.type != null))]
            data.append('mall',JSON.stringify(mall))

            axios.put(`${BASE_END_POINT}projects/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("project UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
      
    }

    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}projects/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("project DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    fileChangedHandler = event => {
        console.log('000')
        console.log(event.target.files[0])
        this.setState({
          img: event.target.files[0],
          imgName: event.target.files[0].name,
          imgType:"data"
        })
        console.log(this.state.img)
        
    }
    removeFile = () => {
        this.setState({
            img:null,
            imgName:'',
            imgType:"url"
        });
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateproject()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    imageSection = () =>{
        return(
          <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
              <Image
                width="100%"
                placeholder={true}
                style={{borderRadius:'6px',width:"100%",height:'280px'}} 
                src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.props.location.state.data.img}
              />
              
              </div>
          </div>
          
      </MDBCol>
        )
    }
    fileChangedHandler2 = event => {
        this.setState({
          pdf: event.target.files[0],
          pdfType:"data",
          pdfName:event.target.files[0].name,
        })
        
    }
    removeFile2 = () => {
        this.setState({
            pdf:null,
            pdfName:'',
            pdfType:"url"
        });
    }
    theVillas(){
        return this.state.theVillas.map((val, i) =>
            <MDBContainer> 
              <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}> 
                <MDBCol md="3">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.type}
                        disabled={true}   
                        value={val.type}
                        treeCheckable={false}
                        onChange={(value) => {
                        console.log(value)
                        let theVillas = [...this.state.theVillas];
                        theVillas[i].type = value;
                        this.setState({ theVillas });
                    }} 
    
                    >
                      <TreeNode value="TOWN" title="TOWN" key="TOWN" />
                      <TreeNode value="TIWN" title="TIWN" key="TIWN" />
                      <TreeNode value="STAND-ALONE" title="STAND-ALONE" key="STAND-ALONE" />
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.available}
                        disabled={this.state.actionType==="VIEW"?true:false}  
                        value={val.available}
                        treeCheckable={false}
                        onChange={(value) => {
                        console.log(value)
                        let theVillas = [...this.state.theVillas];
                        theVillas[i].available = value;
                        this.setState({ theVillas });
                    }} 
    
                    >
                      <TreeNode value={true} title={allStrings.available} key={true} />
                      <TreeNode value={false} title={allStrings.notAvailable} key={false} />
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.theArea}</label>
                    <input value={val.area} min={0} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2" className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theVillas = [...this.state.theVillas];
                        theVillas[i].area = event.target.value;
                        console.log("theVillas",theVillas)
                        this.setState({ theVillas });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                    <input value={val.price} min={0} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2"  className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theVillas = [...this.state.theVillas];
                        theVillas[i].price = event.target.value;
                        console.log("theVillas",theVillas)
                        this.setState({ theVillas });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>   
        )
    }
    theApartments(){
        return this.state.theApartments.map((val, i) =>
            <MDBContainer> 
                <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}> 
                <MDBCol md="3">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.type}
                        disabled={true}   
                        value={val.type}
                        treeCheckable={false}
                        onChange={(value) => {
                        console.log(value)
                        let theApartments = [...this.state.theApartments];
                        theApartments[i].type = value;
                        this.setState({ theApartments });
                    }} 
    
                    >
                        <TreeNode value="ONE-BEDROOM" title="ONE-BEDROOM" key="ONE-BEDROOM" />
                        <TreeNode value="TWO-BEDROOM" title="TWO-BEDROOM" key="TWO-BEDROOM" />
                        <TreeNode value="THREE-BEDROOM" title="THREE-BEDROOM" key="THREE-BEDROOM" />
                        <TreeNode value="FOUR-BEDROOM" title="FOUR-BEDROOM" key="FOUR-BEDROOM" />
                        <TreeNode value="DUPLEX-BEDROOM" title="DUPLEX-BEDROOM" key="DUPLEX-BEDROOM" />
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.available}
                        disabled={this.state.actionType==="VIEW"?true:false}  
                        value={val.available}
                        treeCheckable={false}
                        onChange={(value) => {
                        console.log(value)
                        let theApartments = [...this.state.theApartments];
                        theApartments[i].available = value;
                        this.setState({ theApartments });
                    }} 
    
                    >
                        <TreeNode value={true} title={allStrings.available} key={true} />
                        <TreeNode value={false} title={allStrings.notAvailable} key={false} />
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.theArea}</label>
                    <input value={val.area} min={0} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2" className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theApartments = [...this.state.theApartments];
                        theApartments[i].area = event.target.value;
                        console.log("theApartments",theApartments)
                        this.setState({ theApartments });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                    <input value={val.price} min={0} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2"  className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theApartments = [...this.state.theApartments];
                        theApartments[i].price = event.target.value;
                        console.log("theApartments",theApartments)
                        this.setState({ theApartments });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                </MDBRow>
            </MDBContainer>   
        )
    }
    theMall(){
        return this.state.theMall.map((val, i) =>
            <MDBContainer> 
                <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}> 
                <MDBCol md="3">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.type}
                        disabled={true}   
                        value={val.type}
                        treeCheckable={false}
                        onChange={(value) => {
                        console.log(value)
                        let theMall = [...this.state.theMall];
                        theMall[i].type = value;
                        this.setState({ theMall });
                    }} 
    
                    >
                        <TreeNode value="SHOP" title="SHOP" key="SHOP" />
                        <TreeNode value="CLINIC" title="CLINIC" key="CLINIC" />
                        <TreeNode value="OFFICE" title="OFFICE" key="OFFICE" />
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.available}
                        disabled={this.state.actionType==="VIEW"?true:false} 
                        value={val.available}
                        treeCheckable={false}
                        onChange={(value) => {
                        console.log(value)
                        let theMall = [...this.state.theMall];
                        theMall[i].available = value;
                        this.setState({ theMall });
                    }} 
    
                    >
                        <TreeNode value={true} title={allStrings.available} key={true} />
                        <TreeNode value={false} title={allStrings.notAvailable} key={false} />
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.theArea}</label>
                    <input value={val.area} min={0} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2" className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theMall = [...this.state.theMall];
                        theMall[i].area = event.target.value;
                        console.log("theMall",theMall)
                        this.setState({ theMall });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                    <input value={val.price} min={0} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2"  className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theMall = [...this.state.theMall];
                        theMall[i].price = event.target.value;
                        console.log("theMall",theMall)
                        this.setState({ theMall });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                </MDBRow>
            </MDBContainer>   
        )
    }
    
    render(){
        if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PROJECTS')) return <Redirect to='/Home' />

        return (
            <div className="App">
                <MDBContainer> 
                    <MDBRow>  
                        <MDBCol xl ={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                            <div className="infoCard">
                                <Card className="editCard">
                                    <CardMedia
                                    style={{height:"0px"}}
                                    />
                                    <CardHeader></CardHeader>
                                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                        <div className="topIcon">
                                            <span className="material-icons" style={{color:'white',fontSize:23}}>business</span>
                                        </div>
                                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                        <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                                        </div>
                                    </div>
                                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                                        <MDBContainer>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <form
                                                className="needs-validation"
                                                onSubmit={this.submitHandler}
                                                noValidate
                                                >
                                                    
                                                    <div className="grey-text">
                                                    {this.state.actionType==="VIEW"&&
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                            <div className="md-form">
                                                                <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    }
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                                            <TreeSelect virtual={false}
                                                                showSearch={false} 
                                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                                treeCheckable = {false}         
                                                                treeNodeFilterProp="title"                      
                                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                placeholder={allStrings.type}
                                                                value={this.state.type}
                                                                disabled={this.state.actionType==="EDIT"?false:true} 
                                                                onChange={(value) => {
                                                                    console.log(value)
                                                                    this.setState({type:value});
                                                                }} 
                                                            
                                                            >
                                                                <TreeNode value="COMPOUND" title={allStrings.compound} key="COMPOUND" />
                                                                <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />                                                
                                                                
                                                            </TreeSelect>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                                <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.employeName}</label>
                                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.employeName} type="text" id="form3" name="employeName" className="form-control" onChange={this.changeHandler} required validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                                        <MDBCol md="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                                            <TreeSelect
                                                            value= {this.state.employeCountry}
                                                            showSearch={true} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}    
                                                            disabled={this.state.actionType==="VIEW"?true:false}     
                                                            treeNodeFilterProp="title"                      
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.country}
                                                            showCheckedStrategy= {SHOW_PARENT}
                                                            onChange={(value) => {
                                                                this.setState({employeCountry:value});
                                                                console.log(value)
                                                            }} 
                                                            
                                                            >
                                                                {this.state.countries.map(val=>(
                                                                    <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                                ))}
                                                            </TreeSelect>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                                <label htmlFor="form03" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.employePhone}</label>
                                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.employePhone} type="text" id="form03" name="employePhone" className="form-control" onChange={this.changeHandler} required validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    {/* <MDBRow>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.latitude}</label>
                                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.latitude?this.state.latitude:0}  type="number" step="0.01"  id="form65" name="latitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.longitude}</label>
                                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.longitude?this.state.longitude:0} type="number" step="0.01"  id="form64" name="longitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow> */}
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                                       <MDBCol md="6">
                                                            <div className="md-form">
                                                                <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.ratio}</label>
                                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.ratio} type="number" min={0} id="formn8" className="form-control" name='ratio' onChange={this.changeHandler} required validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                                <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.companyRatio}</label>
                                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.companyRatio} type="number" min={0} id="formn8" className="form-control" name='companyRatio' onChange={this.changeHandler} required validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                                <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.diffOnspotRatio}</label>
                                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.diffOnspotRatio} type="number" min={0} id="formn8" className="form-control" name='diffOnspotRatio' onChange={this.changeHandler} required validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol xl="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.company}</label>
                                                            <TreeSelect virtual={false}
                                                            showSearch={true}
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}         
                                                            treeNodeFilterProp="title"                      
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.company}
                                                            value={this.state.company}
                                                            disabled={this.state.actionType==="EDIT"?false:true} 
                                                            onChange={(value) => {
                                                                console.log(value)
                                                               this.setState({company:value});
                                                            }} 
                                            
                                                        >
                                                        {this.state.companies.map(val=>(
                                                            <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                        ))}
                                                        </TreeSelect>
                                                        </MDBCol>
                                                        
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                                        <MDBCol md="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.nawyApp}</label>
                                                            <TreeSelect virtual={false}
                                                            showSearch={false} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}         
                                                            treeNodeFilterProp="title"                      
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.nawyApp}
                                                            value={this.state.nawyApp}
                                                            disabled={this.state.actionType==="EDIT"?false:true} 
                                                            onChange={(value) => {
                                                                console.log(value)
                                                                this.setState({nawyApp:value});
                                                            }} 
                                            
                                                            >
                                                            <TreeNode value={true} title={allStrings.yes} key={true} />
                                                            <TreeNode value={false} title={allStrings.no} key={false} />                                                
                                                
                                                            </TreeSelect>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.showTop}</label>
                                                        <TreeSelect virtual={false}
                                                            showSearch={false} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}         
                                                            treeNodeFilterProp="title"                      
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.showTop}
                                                            value={this.state.top}
                                                            disabled={this.state.actionType==="EDIT"?false:true} 
                                                            onChange={(value) => {
                                                                console.log(value)
                                                                this.setState({top:value});
                                                           }} 
                                            
                                                            >
                                                            <TreeNode value={true} title={allStrings.yes} key={true} />
                                                            <TreeNode value={false} title={allStrings.no} key={false} />                                                
                                                
                                                            </TreeSelect>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    {this.state.type === "COMPOUND" &&
                                                    <span>
                                                        <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%',marginBottom:'25px',marginTop:'25px'}}>
                                                            <span style={{padding:'10px',background:'#233b5b',borderBottomLeftRadius: '14px',color:'#fff',marginTop:'25px'}}>{allStrings.villas}</span>
                                                        </label>
                                                        {this.theVillas()}
                                                    </span>
                                                    }
                                                    <span>
                                                        <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%',marginBottom:'25px',marginTop:'25px'}}>
                                                            <span style={{padding:'10px',background:'#233b5b',borderBottomLeftRadius: '14px',color:'#fff',marginTop:'25px'}}>{allStrings.apartments}</span>
                                                        </label>
                                                        {this.theApartments()}
                                                    </span>
                                                    {this.state.type === "COMPOUND" &&
                                                    <span>
                                                        <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%',marginBottom:'25px',marginTop:'25px'}}>
                                                            <span style={{padding:'10px',background:'#233b5b',borderBottomLeftRadius: '14px',color:'#fff',marginTop:'25px'}}>{allStrings.mall}</span>
                                                        </label>
                                                        {this.theMall()}
                                                    </span>
                                                    }
                                                    {this.state.type === "COMPOUND" &&this.theMall()}
                                                    {this.state.actionType==="EDIT"&&
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.mainImg}</label>

                                                            <div className="fileUpload2"> 
                                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                            </div>
                                                            <div className="preview">
                                                            {this.state.img&&
                                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                                <img crossorigin="anonymous"alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                                
                                                                <span className="imgName">{this.state.imgName}</span>
                                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                                            </div>
                                                            }
                                                            </div>
                                                            
                                                        </MDBCol>
                                                    </MDBRow>
                                                    }
                                                    <MDBRow>
                                                    {this.state.actionType==="EDIT"&&
                                                        <MDBCol md="12">
                                                        <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.files}</label>
                                                            <div className="fileUpload2"> 
                                                                <input  key={this.state.pdf} type='file' className="upImgs2" onChange={this.fileChangedHandler2} ></input>
                                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                                            </div>
                                                            <div className="preview">
                                                                {this.state.pdfName&&
                                                                <div style={{ display: "inline-block" }}>
                                                                <p className="previewPdf">{this.state.pdfName}
                                                                <MDBIcon icon="times" className="mr-2 removePdf"
                                                                    onClick={() => this.removeFile2()}  ></MDBIcon>
                                                                </p>
                                                                </div>
                                                                }
                                                            
                                                            </div>
                                                        </MDBCol>
                                                    }
                                                        
                                                        <br></br>
                                                        {this.state.pdf != null &&
                                                            <div>
                                                                <a href={this.state.pdf} target="blank" style={{background:'#233b5b',
                                                                color: '#fff',
                                                                padding: '3px 40px',
                                                                marginLeft: '15px'}}
                                                                >
                                                                    {allStrings.openFile}          
                                                                </a>
                                                            </div>
                                                        }
                                                    </MDBRow>
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="text-center">
                                                        {this.state.actionType==="EDIT"&&
                                                        <MDBBtn
                                                        style={{background:'#233b5b',color:'#fff'}}
                                                        rounded
                                                        type="submit"
                                                        className="btn-block z-depth-1a"
                                                        >
                                                            <MDBIcon icon="edit" className="mr-2" />
                                                            {allStrings.update}          
                                                        </MDBBtn>
                                                        }{this.state.actionType==="VIEW"&&
                                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                                            {allStrings.remove}
                                                        </MDBBtn>
                                                        }
                                                        
                                                    </div>
                                                </form>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </CardContent>
                                </Card>
                            </div>
                        </MDBCol>
                        {this.state.actionType==="VIEW"&&this.imageSection()}   
                    </MDBRow>
                </MDBContainer>
            
            </div> 
        )
    }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(projectInfo)
);