import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './project.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import Input from '@material-ui/core/Input';
import {hasValue} from '../../validations/validations'

let headers = [
  { label: "#", key: "id" },
  { label: "Name", key: "name" },
  { label: "Ratio", key: "ratio" },
  { label: "Diff Onspot Ratio", key: "diffOnspotRatio" },
  { label: "On Spot", key: "onSpot" },
  { label: "Company", key: "company" },
  { label: "Ratio For Company", key: "companyRatio" },
];
 
let csvData = [
];

class project extends React.Component {
  
  state={
    project:[],
    project2:[],
    loading:true,
    refresh:false,
    search:"",
    page:1,
    pages:1,
    csvData:[]
  }

  constructor(props){
    super(props)
    this.csvLinkEl = React.createRef();
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    this.getProjects(1)
    this.getProjectsWithoutPagenation()

  }


  getProjects = (page,search) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}projects?page=${page}`
    if(search){
      url = `${BASE_END_POINT}projects?page=${page}&&search=${search}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("projects   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.name_ar,
        item.name_en,
        item.type === "COMPOUND"?{type:'tag',value:allStrings.compound}:{type:'tag',value:allStrings.separated},
        item.ratio,
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        project:response.data.data,
        project2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getProjectsWithoutPagenation = (search) =>{
    let url = `${BASE_END_POINT}projects/withoutPagenation/get`
    if(search){
      url = `${BASE_END_POINT}projects/withoutPagenation/get?search=${search}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("projects   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{name:this.props.isRTL?item.name_ar:item.name_en},
            ...{type:item.type === "COMPOUND"?allStrings.compound:allStrings.separated},
            ...{ratio:item.ratio},
            ...{diffOnspotRatio:item.diffOnspotRatio},
            ...{onSpot:(item.ratio - item.diffOnspotRatio) / 100 },
            ...{company:item.company?item.company.id:''},
            ...{companyRatio:item.companyRatio}
          };
        });
        this.setState({csvData:csvData})
        console.log(csvData)
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}projects/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("Year DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getProjects(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="AddProject">
        <Fab
          style={{background:'#233b5b',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getProjects(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getProjects(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getProjects(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#233b5b !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getProjects(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getProjects(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PROJECTS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
            
          <Table
          reload = {
            <span 
            style={{position: 'absolute',margin: '7px 0px',color: 'rgb(67, 66, 93)',fontWeight: '600',cursor: 'pointer'}}
            onClick={() => this.getProjects(1)}
            class="material-icons">
              replay
            </span>
          }
          csv = {
            <CSVLink 
                data={this.state.csvData} 
                headers={headers} 
                filename={"projects.csv"}
                ref={this.csvLinkEl}
              >
                <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
              </CSVLink>
          }
          searchInput = {
            <Input 
            onKeyPress={(e)=>{
              if (e.key === 'Enter') {
                if(hasValue(""+e.target.value)){
                  console.log("ENTER   ",e.target.value)
                  this.setState({search:e.target.value})
                  this.getProjects(1,e.target.value)
                  this.getProjectsWithoutPagenation(e.target.value)
                }else{
                  message.warning(allStrings.noValue);
                  console.log("ENTER NO VALUE   ",e.target.value)
                }
              }
             }}  
            placeholder={allStrings.Search} style={{direction:this.props.isRTL?'rtl':'ltr'}}inputProps={{ 'aria-label': 'description' }} />
          }
            title={allStrings.projects}
            icon='business'
            data={this.state.project}
            data2={this.state.project2}
            tableColumns={["#",allStrings.arabicName,allStrings.englishName,allStrings.type,allStrings.ratio,allStrings.action]}
            loading={this.state.loading}
            deleteButton={(id)=>{this.delete(id)}}
            path='/projectInfo'
            actionIndex={3}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(project)
);

